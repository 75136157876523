import React, { lazy, Suspense, Fragment, useEffect } from "react";
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";
import { LoadingGif } from "./components/elements/Loading";
import { Provider } from "react-redux";
import store from "./store";
import setAuthToken from "./utils/setAuthToken";
import { loadUser, logout } from './actions/register';
import PrivateRoute from './utils/routing/PrivateRoute';

const Alerts = lazy(() => import('./components/elements/Alerts'));
// const Login = lazy(() => import("./components/AuthPages/Login"));
// const Register = lazy(() => import("./components/AuthPages/Register"));
// const Confirm = lazy(() => import('./components/AuthPages/Confirm'));
// const Forgot = lazy(() => import('./components/AuthPages/Forgot'));

const ArtistProfile = lazy(() => import('./components/Pages/ArtistProfile'));
const HomePage = lazy(() => import('./components/Pages/HomePage'))
const MyArtist = lazy(() => import('./components/Pages/MyArtist'))

if (localStorage.token) {
  setAuthToken(localStorage.token);
}

const App = () => {

  return (
    <Provider store={store}>
      <Router>
        <Suspense fallback={LoadingGif}>
          <Fragment>
            <section>
              <Alerts />
              <Switch>
                <Route path='/myartist' component={MyArtist}/>
                <Route path='/artist/:userId' component={ArtistProfile}/>
                <Route path='/' component={HomePage}/>

                <Redirect from="*" to="/" />
              </Switch>
            </section>
          </Fragment>
        </Suspense>
      </Router>
    </Provider>
  );
}


export default App;